/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveAboveCta } from "@dataResolvers";
import moment from "moment";
import { Page as PageTemplate } from "@pageTemplates";
import QueryContainer from "@templates/QueryContainer";

moment.locale("en");

export const query = graphql`
  query EventsIndexQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "eventsIndex", siteId: $siteId) {
        title
        ... on Craft_eventsIndex_eventsIndex_Entry {
          #Hero
          heroTitle
          entrySubtitle
          #No events copy
          summary
          #Above Footer CTAs
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
          #Meta
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entries(
        section: "events"
        orderBy: "eventStartDate asc"
        siteId: $siteId
      ) {
        ... on Craft_events_event_Entry {
          url
          title
          heroTitle
          eventStartDate
          eventEndDate
          metaDescription
          metaFeaturedImage(withTransforms: ["carouselExtraLarge"]) {
            width(transform: "carouselExtraLarge")
            height(transform: "carouselExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "carouselExtraLarge")
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  if (!entry) return null;
  const {
    title,
    heroTitle,
    entrySubtitle,
    summary,
    aboveFooterCtaImageBlocks,
    metaFeaturedImage,
    metaDescription,
  } = entry;

  return {
    title,
    hero: {
      title: heroTitle || title,
      subtitle: entrySubtitle,
      image: resolveImage(metaFeaturedImage),
    },
    summary,
    events: entries?.map(event => {
      return {
        heading: event?.heroTitle || event?.title,
        summary: event?.metaDescription,
        image: resolveImage(event?.metaFeaturedImage),
        eventStartDate: moment(event?.eventStartDate).format("l"),
        eventEndDate: moment(event?.eventEndDate).format("l"),
        link: {
          text: `Learn More`,
          url: event?.url,
        },
        width: "third",
        fullWidthImage: true,
        spacingBottom: true,
      };
    }),
    cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
